@font-face {
    font-family: 'azuki';
    src: url('./components/fonts/azuki-webfont.woff2') format('woff2'),
         url('./components/fonts/azuki-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'kawaii';
    src: url('./components/fonts/ktegaki-webfont.woff2') format('woff2'),
         url('./components/fonts/ktegaki-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
*,*:focus,*:hover{
    outline: none !important;
}

.App-link {
    color: #61dafb;
}
html{
    height: 100%;
}
body{
    width: 100%;
    overflow: none;
    font-family: sans-serif;
    background-color: #5499E7;
    color: #fff;
    margin: 0;
    font-size: 16px;
}
#logo{
    width: 220px;
    height: 85px;
    z-index: 998;
    background-size: 300px;
    background-repeat: no-repeat; 
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    margin-bottom: 50px;
}
#logo img{
    max-width: 100%;
    display: block;
}
.content{
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    min-height: calc(100vh - 60px);
    animation-name: fade-in;
    animation-duration: 1s;
}

/* HOME */
#home{
    height: auto;
    top: 0px;
    padding: 10px 60px;
    width: calc(100% - 120px);
    position: absolute;
}
#home #footer{
    animation-name: fade-in;
    animation-duration: 5s;
}
#home-logo{
    width: 280px;
    height: 115px;
    z-index: 998;
    background-size: 300px;
    background-repeat: no-repeat; 
    margin: 0 auto;
    padding-top: calc(50vh - 90px);
    position: fixed;
    left: 50%;
    transform: translateX(-140px);
    overflow: visible;
    animation-name: fade-in;
    animation-duration: 1.25s;
    animation-timing-function: ease-in;
}
#home-logo img{
    max-width: 100%;
    display: block;
}
#home-footer{
    height: 50px;
    position: fixed !important;
    bottom: 0px;
    width: 100%;
    left: 0px;
}

/* ABOUT */
#about{
    height: auto;
    top: 0px;
    padding: 10px 60px;
    width: calc(100% - 120px);
    position: absolute;
}
#about-box{
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: azuki, "Tsukushi A Round Gothic", Yu Gothic;
    text-align: center;
}
.about-bio{
    display: block;
    /*width: 90%;
    padding: 0 5%;*/
    height: auto;
    overflow: hidden;
}
.bio-1{
    animation-name: fade-in;
    animation-duration: 0.75s;
}
.bio-2{
    animation-name: fade-in;
    animation-duration: 1.75s;
}
.bio-title{
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 5px;
}
.bio-image{
    margin: 10px 0;
    display: inline-block;
    width: 25%;
    height: 200px;
    background-color: rgba(0,0,0,0);
    float: left;
}
.bio-text{
    margin: 10px 0;
    display: inline-block;
    width: calc(75% - 20px);
    height: auto;
    background-color: rgba(255,255,255,.15);
    border-radius: 10px;
    font-family: azuki, "Tsukushi A Round Gothic", Yu Gothic;
    margin-top: 10px;
    float: right;
    padding: 10px;
    margin-bottom: 20px;
}
.bio-description{
    padding-bottom: 10px;
}
.bio-buttons a{
    opacity: 0.6;
    transition-duration: 0.2s;
}
@media (hover: hover) {
    .bio-buttons a:hover{
        opacity: 1;
        transition-duration: 0.2s;
    }
}
.text-right{
    text-align: right;
}
.text-left{
    text-align: left;
}
.avatar_video{
    width: 80%;
    height: auto;
    border-radius: 100%;
    z-index: 999;
}
.avatars{
    top: 50%;
    position: static;
    transform: translateY(-100%);
    display: block;
    width: 100%;
    margin: 0 10%;
    cursor: pointer;
    z-index: 999;
}
.avatars_png{
    top: 50%;
    position: static;
    display: block;
    width: 100%;
    margin: 0 10%;
    cursor: pointer;
    z-index: 1;
}
.avatar_image{
    display: inline-block !important;
    width: 80%;
    height: auto;
    border-radius: 100%;
    z-index: 10;
}
.avatar-left{
    float: left;
}
.avatar-right{
    float: right;
}

/* GAMES */
#games{
    height: auto;
    position: absolute;
    top: 0px;
    padding: 10px 60px;
    width: calc(100% - 120px);
        animation-name: fade-in;
    animation-duration: .75s;
}
#other-games{
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    justify-content: space-evenly;
    padding-bottom: 20px;
    animation-name: fade-in;
    animation-duration: 2s;
}
.feature{
    display: block;
    border-radius: 10px;
    width: calc(100% - 10px);
    padding-top: 56% !important;
    overflow: hidden;
}
.game-box-holder{
    width: calc(100% - 10px);
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
    animation-name: fade-in;
    animation-duration: 1s;
    transition-duration: .15s;
}
.game-box{
    width: calc(100%);
    display: inline-block;
    padding-top: 57.75%;
    cursor: pointer;
    overflow: hidden;
}
@media (hover: hover) {
    .game-box-holder:hover{
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.5);
        transition-duration: .15s;
    }
}
.game-banner{
    height: 18px;
    color: #4195c0;
    background-color: #fff;
    width: auto;
    padding: 12px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    float:right;
    right: 0px;
    margin-top: -38px;
    text-align: center;
    border-radius: 15px 0 10px 0px;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    line-height: 20px;
    font-family: azuki, "Tsukushi A Round Gothic", Yu Gothic;
}
.game-bg{
    background-size: 100.5% !important;
    transition-duration: 0.25s;
}
@media (hover: hover) {
    .game-bg:hover{
        background-size: 102% !important;
        transition-duration: 0.25s;
    }
}

/* CONTACT */
#contact{
    top: 0px;
    padding: 10px 60px;
    width: calc(100% - 120px);
}
#contact-box{
    padding-top: 20px;
    padding-bottom: 25px;
    font-family: azuki, "Tsukushi A Round Gothic", Yu Gothic;
    text-align: center;
}
#contact-box a{
    opacity: 0.6;
    transition-duration: 0.2s;
    text-decoration: none;
    color: #fff;
}
@media (hover: hover) {
    #contact-box a:hover{
        opacity: 1;
        transition-duration: 0.2s;
    }
}
#social-media{
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: azuki, "Tsukushi A Round Gothic", Yu Gothic;
    text-align: center;
}
#social-media a{
    opacity: 0.6;
    transition-duration: 0.2s;
}
@media (hover: hover) {
    #social-media a:hover{
        opacity: 1;
        transition-duration: 0.2s;
    }
}   
#contact-content{
    animation-name: fade-in;
    animation-duration: 0.75s;
}
.contact-1{
    animation-name: fade-in;
    animation-duration: 1.5s;
}
.contact-2{
    animation-name: fade-in;
    animation-duration: 2.25s;
}

/* MISC */
.title{
    font-family: azuki,  "Tsukushi A Round Gothic", Yu Gothic;
    font-size: 28px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    line-height: 28px;
}
.title:before, .title:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 20%;
    height: 1.5px;
    content: '\a0';
    background-color: white;
    margin-top: 1px;
    margin-left: 10px;
}
.title:before {
    margin-left: calc(-20% - 12px);
    text-align: right;
}
.subtitle{
    font-family: azuki,  "Tsukushi A Round Gothic", Yu Gothic;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    line-height: 25px;
    font-size: 25px;
    font-weight: bold;
}
#header .main-logo{
    padding-top: 5px;
    max-height: 110px;
}
#footer{
    right: 0px;
    font-family: azuki, "Tsukushi A Round Gothic";
    font-size: 12px;
    width: 100%;
    height: 40px;
    text-align: center;
    position: absolute;
    padding-bottom: calc(10px + 1*env(safe-area-inset-bottom));
}
#footer a{
    text-decoration: none;
    opacity: 0.6;
    transition-duration: 0.2s;
    color: #fff;
}
@media (hover: hover) {
    #footer a:hover{
        opacity: 1;
        transition-duration: 0.2s;
    }
}
.copyright{
    font-size: 12px;
    font-family: arial;
}
.hidden{
    display: none !important;
}
#country-button{
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 9999;
}
#language-toggle{
    color: #fff;
    font-family: azuki, "Tsukushi A Round Gothic", Yu Gothic;
    font-size: 14px;
    position: fixed;
    width: 50px;
    right: 5px;
}
button{
    background: none;
    padding: none;
    border: none;
    cursor: pointer;
}
.button{
    opacity: 1;
    background-color: #6fa9eb;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: inline-block;
    margin: 5px;
    transition-duration: 0.3s;
} 
@media (hover: hover) {
    .button:hover{
        background-color: #8dbbef;
        transition-duration: 0.3s;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
    }
}

/*NAVBAR*/
#navigation{
    z-index: 999;
    background-color: #f0e9eb00;
    width: auto;
    height: auto;
    display: block;
    position: fixed;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.nav-circle button{
    padding: 0px;
    margin: 0px;
}
.nav-circle{
    background-color: #fff;
    width: auto;
    max-width: 40px;
    height: 40px;
    border-radius: 25px;
    margin-top:  10px;
    margin-bottom: 10px;
    transition: 0.4s;
    overflow: hidden;
}
@media (hover: hover) {
    .nav-circle:hover{
        max-width: 200px;
        transition-duration: 0.5s;
    }
}
.nav-1{
    animation-name: slide-in-left;
    animation-duration: 1.2s;
    animation-timing-function: ease-out;
    animation-direction: normal;
}
.nav-2{
    animation-name: slide-in-left;
    animation-duration: 1.4s;
    animation-timing-function: ease-out;
    animation-direction: normal;
}
.nav-3{
    animation-name: slide-in-left;
    animation-duration: 1.6s;
    animation-timing-function: ease-out;
    animation-direction: normal;
}
.nav-4{
    animation-name: slide-in-left;
    animation-duration: 1.8s;
    animation-timing-function: ease-out;
    animation-direction: normal;
}
.icon{
    margin-top: 2px;
    margin-left: 2px;
    width: 36px;
    height: 36px;
    border-radius: 25px;
}
.nav-title{
    display: inline-block;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    padding: 0px 15px 0px 5px;
    vertical-align: bottom;
    color: #5499E7;
    font-family: azuki, "Tsukushi A Round Gothic", Yu Gothic;
    font-weight: 600;
}
.nav-icon{
    display: inline-block;
    float: left;
}

/*ANIMATIONS*/
@keyframes slide-in-left {
    0% {margin-left: -50px; opacity: 0;}
    66% {margin-left: -50px; opacity: 0;}
    100% {margin-left: 0px; opacity: 100;}
}
@keyframes slide-in-right {
    0% {margin-right: -50px; opacity: 0;}
    66% {margin-right: -50px; opacity: 0;}
    100% {margin-right: 0px; opacity: 100;}
}
@keyframes fade-in {
    from {opacity: 0;}
    to {opacity: 1;}
}
@keyframes fade-in-delay {
    0% {opacity: 0;}
    50% {opacity: 0;}
    100% {opacity: 1;}
}

/* Mobile Portrait */
@media screen and (min-width: 240px) and (max-width: 699px) and (max-aspect-ratio: 1/1){
    body{
        font-size: 14px;
    }
    #home-logo{
        max-width: 100%;
        padding-top: calc(50vh - 165px);
        bottom: 47%;
    }
    #other-games{
        grid-template-columns: 1fr;
    }
    .content{
        width: 100%;
        min-height: calc(100vh - 50px);
    }
    #navigation{
        left: calc(100% - 45px);
        transform: translateY(-50%);
        float:right;
    }
    .nav-circle{
        max-width: 36px;
        height: 36px;
    }
    .nav-1{
        animation-name: fade-in-delay;
        animation-duration: 2.5s;
        animation-timing-function: ease-in;
        animation-direction: normal;
    }
    .nav-2{
        animation-name: fade-in-delay;
        animation-duration: 2.5s;
        animation-timing-function: ease-in;
        animation-direction: normal;
    }
    .nav-3{
        animation-name: fade-in-delay;
        animation-duration: 2.5s;
        animation-timing-function: ease-in;
        animation-direction: normal;
    }
    .nav-4{
        animation-name: fade-in-delay;
        animation-duration: 2.5s;
        animation-timing-function: ease-in;
        animation-direction: normal;
    }
    .icon{
        margin-right: 2px;
        width: 32px;
        height: 32px;
    }
    .nav-title{
        display: none;
    }
    .bio-image{
        display: block;
        width: 100%;
        height: 50px;
        left: 50%;
        position: relative;
        transform: translateX(calc(-67.5px));
    }
    .bio-text{
        display: block;
        width: calc(100% - 20px);
        padding-top: 65px;
    }
    .text-right{
        text-align: center;
    }
    .text-left{
        text-align: center;
    }
    .avatars{
        height: 50px;
        margin: 0px;
    }
    .avatars_png{
        height: 50px;
        margin: 0px;
    }
    .avatar_video{
        width: 125px;
        height: 125px;
        display: none;
    }
    .avatar_image{
        width: 125px;
        height: 125px;
    }
    .bio-buttons img{
        width: 50px;   
    }
    #social-media img{
        width: 60px;
        max-width: 25%;
    }
    .game-box-holder{
        width: calc(100%);
        margin-left: 0px;
        margin-right: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    }
    #home, #games, #about, #contact{
        width: calc(100% - 110px);
        padding: 10px 55px;
    }
    #contact{
        position: absolute;
        padding-bottom: 50px;
        min-height: calc(100% - 60px);
    }    
    .subtitle{
        font-size: 20px;
        line-height: 28px;
    }
}

/*iPhone Landscape*/
@media only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : landscape) 
and (-webkit-device-pixel-ratio : 3), 
only screen
and (min-device-width : 375px)
and (max-device-width : 812px)
and (orientation : landscape) 
and (-webkit-device-pixel-ratio : 2), 
{
    body{
        font-size: 0.6em;
    }
    #home-logo{
        max-width: 100%;
        padding-top: calc(50vh - 165px);
        bottom: 47%;
    }
    .content{
        width: calc(100% - 2*env(safe-area-inset-right));
        min-height: calc(100vh - 50px);
    }
    #country-button{
        right: calc(5px + env(safe-area-inset-right));
    }
    #navigation{
        left: calc(100% - 45px - env(safe-area-inset-right));
    }
    .nav-circle{
        max-width: 36px;
        height: 36px;
    }
    .icon{
        margin-right: 2px;
        width: 32px;
        height: 32px;
    }
    .nav-title{
        display: none;
    }
    .bio-image{
        display: block;
        width: 100%;
        height: 50px;
        left: 50%;
        position: relative;
        transform: translateX(calc(-67.5px));
    }
    .bio-text{
        display: block;
        width: calc(100% - 20px);
        padding-top: 65px;
    }
    .text-right{
        text-align: center;
    }
    .text-left{
        text-align: center;
    }
    .avatars{
        height: 50px;
        margin: 0px;
    }
    .avatars_png{
        height: 50px;
        margin: 0px;
    }
    .avatar_video{
        width: 125px;
        height: 125px;
        display: none;
    }  
    .avatar_image{
        width: 125px;
        height: 125px;
    }
    .bio-buttons img{
        width: 50px;   
    }
    #social-media img{
        width: 60px;
    }
    #other-games{
        grid-column-gap: 10px;
    }
    .game-box-holder{
        width: calc(100%);
        margin-left: 0px;
        margin-right: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3);
    }
    #home, #games, #about, #contact{
        width: calc(100% - 110px);
        padding: 10px 55px;
    }
    #contact{
        position: absolute;
        padding-bottom: 50px;
    }
    .game-banner{
        font-size: 14px;
    }
    .subtitle{
        font-size: 20px;
    }
}


